.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  position: fixed;
  height: calc(100% - 4px);
  width: calc(100% - 4px);
}

.Content {
  flex: 1 0 auto;
  margin-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
  text-justify: auto;
}

.Footer {
  flex-shrink: 0;
  height: 20px;
  font-size: 14px;
  position: fixed;
  bottom: 0;
  width: 100%;
  color: #a5a5a5;
  background-color: #ffffff;
  opacity: 1;
}

.Title {
  margin-top: 25px;
  font-size: 36px;
}

@media screen and (orientation:landscape)
{
  .Title {
    display: none;
  }

  .Content {
    margin-top: 0;
  }

}